<template>
  <div>
    <div class="table-box">
      <div class="list-title">
        <div class="title-wrap">
          标签组名称:<span>{{tagInfo.groupName}}</span>
        </div>
        <div class="title-wrap">
          组内标签数:<span>{{tagInfo.tagCount}}</span>
        </div>
        <div class="title-wrap">
          组内客户总数:<span>{{tagInfo.customerCount}}</span>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="TagName" label="标签名称"></el-table-column>
        <el-table-column prop="CustomerCount" label="关联客户数">
          <template #default="scope">
            <div class="cell-wrap">
              <div>{{ scope.row.CustomerCount }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="LastSyncTime" label="最近同步时间">
          <template #default="scope">
            <div>
              <div v-if="scope.row.SyncStatus!=0">{{scope.row.LastSyncTime}}</div>
              <div v-else>-</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="SyncStatus" label="标签下客户同步状态">
          <template #default="scope">
            <div>
              <div v-if="scope.row.SyncStatus==2">全部成功</div>
              <div v-else-if="scope.row.SyncStatus==11">部分成功</div>
              <div v-else-if="scope.row.SyncStatus==1">同步中({{scope.row.SyncProcess}}%)</div>
              <div v-else>未同步</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220">
          <template #default="scope">
            <el-button type="primary" @click="syncCustom(scope.row)" v-if="scope.row.SyncStatus!=1">同步</el-button>
            <el-button type="primary" @click="NoSyncCustom(scope.row)" v-else>不同步</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search } from "@element-plus/icons";
import {CorpTagGetStats,CorpTagSyncCustomerByTag,CorpTagCancelJob} from "@/helper/group.js"
import Tool from "@/assets/js/tools";

export default {
  name: "SyncGroupList",
  components: {
    Search
  },
  setup() {
    const searchOption = reactive({
      key: "",
    });
    const tagNo=useRoute().query.TagGroupSerialNo;
    const tableData = ref([]);
    const tagInfo=reactive({
      groupName:"",
      tagCount:0,
      customerCount:0,
      syncStatus:0
    })
    onMounted(() => {
      searchAction();
    });

    /******************************搜索**********************************/
    function searchAction() {
      let param={
        PageNum:1,
        PageSize:9999999,
        TagGroupSerialNo:tagNo
      }
      CorpTagGetStats(param).then(res=>{
        console.log(res);
        tableData.value=res.List
        tagInfo.groupName=res.TagGroupName
        tagInfo.tagCount=res.TotalTagCount
        tagInfo.customerCount=res.TotalCustomerCount
        tagInfo.syncStatus=res.SyncTagStatus
      })
    }
    //同步
    function syncCustom(item) {
        CorpTagSyncCustomerByTag({TagSerialNo:item.TagSerialNo}).then(res=>{
          searchAction()
        })
    }
    //不同步
    function NoSyncCustom(item) {
        let param={
          Type:2,
          TagSerialNo:item.TagSerialNo,
          JobGUID:item.JobGUID
        }
        ElMessageBox.confirm(`取消同步后会终止同步操作，导致部分用户无法完成同步，但对已经同步成功的用户不造成影响，您确定要这样做吗？`, "确认提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          CorpTagCancelJob(param).then(res=>{
            searchAction();
          })
        }).catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }


    return {
      searchOption,
      tableData,
      searchAction,
      syncCustom,
      NoSyncCustom,
      tagInfo
    };
  },
};
</script>
<style lang="scss" scoped>
:deep().table-search span {
  margin-right: 0;
}
.list-title{
    justify-content: flex-start;
}
.title-wrap{
    width: 200px;
    span{
        color:$color-common;
    }
}
</style>